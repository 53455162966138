import { generateMapByOpts } from '@/common/utils'

const realnameExamineOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待认证',
    value: 0
  },
  {
    text: '已认证',
    value: 1
  }
]

const politicalOpts = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '中共党员',
    value: '中共党员'
  },
  {
    text: '共青团员',
    value: '共青团员'
  },
  {
    text: '群众',
    value: '群众'
  }
]

const maritalStatusOpts = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '未婚',
    value: 0
  },
  {
    text: '已婚',
    value: 1
  },
  {
    text: '离异',
    value: 2
  },
  {
    text: '丧偶',
    value: 3
  }
]

const cardTypeOpts = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '身份证',
    value: '1'
  },
  {
    text: '军官证',
    value: '2'
  },
  {
    text: '护照',
    value: '3'
  },
  {
    text: '其他',
    value: '4'
  },
  {
    text: '驾驶证',
    value: '5'
  },
  {
    text: '港澳通行证',
    value: '6'
  },
  {
    text: '台胞证',
    value: '7'
  },
  {
    text: '户口本',
    value: '8'
  },
  {
    text: '警官证',
    value: '9'
  }
]

const sexOpts = [
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]

const realnameExamineMap = generateMapByOpts(realnameExamineOps)

const maritalStatusMap = generateMapByOpts(maritalStatusOpts)

const cardTypeMap = generateMapByOpts(cardTypeOpts)

const sexMap = generateMapByOpts(sexOpts)

const userSourceMap = {
  1: 'app注册用户',
  2: '快递用户',
  3: '活动用户',
  4: '导入用户'
}
const adminStatusMap = {
  1: '正常',
  2: '停用',
  3: '注销'
}

export {
  realnameExamineOps,
  realnameExamineMap,
  userSourceMap,
  politicalOpts,
  maritalStatusOpts,
  maritalStatusMap,
  cardTypeOpts,
  cardTypeMap,
  sexOpts,
  sexMap,
  adminStatusMap
}
