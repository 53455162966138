var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-user-mgr-form-contianer" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            queryUrl: _vm.queryUrl,
            submitUrl: _vm.submitUrl,
            submitContentType:
              "application/x-www-form-urlencoded; charset=UTF-8",
          },
          on: { update: _vm.update },
        },
        [
          _c("el-form-item", { attrs: { label: "姓名" } }, [
            _c("div", [_vm._v(_vm._s(_vm.form.userName))]),
          ]),
          _c("el-form-item", { attrs: { label: "手机号" } }, [
            _c("div", {
              domProps: { textContent: _vm._s(_vm.form.mobileNum) },
            }),
          ]),
          _c("el-form-item", { attrs: { label: "性别" } }, [
            _c("div", [_vm._v(_vm._s(_vm.sexMap[_vm.form.sex]))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "是否关联组织", prop: "isRelateOrg" } },
            [_c("div", { domProps: { textContent: _vm._s(_vm.isRelateOrg) } })]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属项目", prop: "communityName" } },
            [
              _c("a", {
                staticClass: "link",
                domProps: { textContent: _vm._s(_vm.communityName) },
                on: { click: _vm.showOrgsModal },
              }),
            ]
          ),
          _c("el-form-item", { attrs: { label: "政治面貌" } }, [
            _c("div", [_vm._v(_vm._s(_vm.form.politicsStatus))]),
          ]),
          _c("el-form-item", { attrs: { label: "民族" } }, [
            _c("div", [_vm._v(_vm._s(_vm.form.nation))]),
          ]),
          _c("el-form-item", { attrs: { label: "籍贯" } }, [
            _vm._v("\n      " + _vm._s(_vm.form.nativePlace) + "\n    "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "婚姻状况", prop: "isMarry" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.maritalStatusMap[_vm.form.isMarry]) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "证件类型", prop: "cardType" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cardTypeMap[_vm.form.cardType]) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "证件号码", prop: "cardNum" } },
            [_vm._v("\n      " + _vm._s(_vm.form.cardNum) + "\n    ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "出生日期", prop: "birthday" } },
            [_vm._v("\n      " + _vm._s(_vm.form.birthday) + "\n    ")]
          ),
          _c("el-form-item", { attrs: { label: "称谓", prop: "alias" } }, [
            _vm._v("\n      " + _vm._s(_vm.form.alias) + "\n    "),
          ]),
          _c("el-form-item", { attrs: { label: "电话", prop: "phoneNum" } }, [
            _vm._v("\n      " + _vm._s(_vm.form.phoneNum) + "\n    "),
          ]),
          _c("el-form-item", { attrs: { label: "职业", prop: "duty" } }, [
            _vm._v("\n      " + _vm._s(_vm.form.duty) + "\n    "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "邮件地址", prop: "emailAddress" } },
            [_vm._v("\n      " + _vm._s(_vm.form.emailAddress) + "\n    ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "数据来源", prop: "userSource" } },
            [
              _c("div", {
                domProps: { textContent: _vm._s(_vm.userSourceText) },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "所属项目---关联组织",
            visible: _vm.orgsModalVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.orgsModalVisible = $event
            },
          },
        },
        _vm._l(_vm.orgInfoList, function (item, index) {
          return _c(
            "el-row",
            { key: index, staticClass: "orgs-modal-item" },
            [
              _c("el-col", {
                attrs: { span: 8 },
                domProps: { textContent: _vm._s(item.communityName) },
              }),
              _c("el-col", {
                attrs: { span: 16 },
                domProps: { textContent: _vm._s(item.orgNames) },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }