<template>
  <div class="register-user-mgr-form-contianer">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      submitContentType="application/x-www-form-urlencoded; charset=UTF-8"
      @update="update"
    >
      <el-form-item label="姓名">
        <div>{{form.userName}}</div>
      </el-form-item>
      <el-form-item label="手机号">
        <div v-text="form.mobileNum"></div>
      </el-form-item>
      <el-form-item label="性别">
        <div>{{sexMap[form.sex]}}</div>
      </el-form-item>
      <el-form-item label="是否关联组织" prop="isRelateOrg">
        <div v-text="isRelateOrg"></div>
      </el-form-item>
      <el-form-item label="所属项目" prop="communityName">
        <a class="link" @click="showOrgsModal" v-text="communityName"></a>
      </el-form-item>
      <el-form-item label="政治面貌">
        <div>{{form.politicsStatus}}</div>
      </el-form-item>
      <el-form-item label="民族">
        <div>{{form.nation}}</div>
      </el-form-item>
      <el-form-item label="籍贯">
        {{form.nativePlace}}
      </el-form-item>
      <el-form-item label="婚姻状况" prop="isMarry">
        {{maritalStatusMap[form.isMarry]}}
      </el-form-item>
      <el-form-item label="证件类型" prop="cardType">
        {{cardTypeMap[form.cardType]}}
      </el-form-item>
      <el-form-item label="证件号码" prop="cardNum">
        {{form.cardNum}}
      </el-form-item>
      <el-form-item label="出生日期" prop="birthday">
        {{form.birthday}}
      </el-form-item>
      <el-form-item label="称谓" prop="alias">
        {{form.alias}}
      </el-form-item>
      <el-form-item label="电话" prop="phoneNum">
        {{form.phoneNum}}
      </el-form-item>
      <el-form-item label="职业" prop="duty">
        {{form.duty}}
      </el-form-item>
      <el-form-item label="邮件地址" prop="emailAddress">
        {{form.emailAddress}}
      </el-form-item>
      <el-form-item label="数据来源" prop="userSource">
        <div v-text="userSourceText"></div>
      </el-form-item>
    </form-panel>
    <el-dialog
      title="所属项目---关联组织"
      :visible.sync="orgsModalVisible">
      <el-row class="orgs-modal-item" v-for="(item, index) in orgInfoList" :key="index">
        <el-col :span="8" v-text="item.communityName"></el-col>
        <el-col :span="16" v-text="item.orgNames"></el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { userSourceMap, politicalOpts, maritalStatusMap, cardTypeMap, sexMap } from './map'
import { getDetailURL, saveURL, getNationURL } from './api'
import province from '@/../public/static/json/province.json'
import Vue from 'vue'
import { Col, Row } from 'element-ui'
Vue.use(Col)
Vue.use(Row)

export default {
  name: 'registerUserMgrForm',
  data () {
    return {
      politicalOpts,
      maritalStatusMap,
      cardTypeMap,
      sexMap,
      nationOpts: [],
      nativeOpts: [],
      orgInfoList: [],
      queryUrl: getDetailURL,
      submitUrl: saveURL,
      form: {
        userName: '',
        mobileNum: '',
        sex: 1
      },
      isRelateOrg: '',
      communityName: '',
      orgsModalVisible: false,
      userSourceText: '',
      width: 182
    }
  },
  created () {
    this.getNations()
    this.nativeOpts = [{ text: '请选择', value: undefined }]
    this.nativeOpts = [...this.nativeOpts, ...province.name.map(province => ({ text: province, value: province }))]
  },
  mounted () {
    this.$refs.form.getData({ id: this.$route.query.id })
    this.$setBreadcrumb('编辑')
  },
  methods: {
    async getNations () {
      const { data } = await this.$axios.get(getNationURL)
      this.nationOpts = [{ text: '请选择', value: undefined }]
      this.nationOpts = [...this.nationOpts, ...data.map(text => ({ text, value: text }))]
    },
    update (data) {
      /* eslint-disable */
      [
        'id',
        'mobileNum',       // 手机号
        'userName',        // 姓名
        'sex',             // 性别
        'nation',          // 民族
        'duty',            // 职业
        'emailAddress',    // 邮件地址
        'politicsStatus',  // 政治面貌
        'nativePlace',     // 籍贯
        'alias',           // 称谓
        'birthday',        // 出生日期
        'cardNum',         // 证件号码
        'isMarry',         // 婚姻状况
        'cardType',        // 证件类型
        'phoneNum',        // 电话号码
        'userSource'       // 数据来源
      ].forEach(key => { 
        if (data[key]) {
          this.form[key] = data[key] 
        }
      })
      this.isRelateOrg = data.orgInfoList.length === 0 ? '否' : '是'
      this.communityName = data.orgInfoList.map(item => item.communityName).join(',')
      this.orgInfoList = data.orgInfoList.map(item => ({ ...item, orgNames: item.orgNames.join(',') }))
      this.userSourceText = userSourceMap[data.userSource]
    },
    showOrgsModal () {
      // 显示所属组织的弹框
      this.orgsModalVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .link {
    color: #1b8cf2;
    cursor: pointer;
  }
  .orgs-modal-item{
    text-align: left;
  }
</style>
