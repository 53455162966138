// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}user/register/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}user/register/list/export`
// 获取民族列表
const getNationURL = `${API_CONFIG.butlerBaseURL}perfectOwner/getNation`

// 获取表单详情
const getDetailURL = `${API_CONFIG.butlerBaseURL}user/register/detail`

// 编辑保存
const saveURL = `${API_CONFIG.baseURL}serverOwnerAction!updatebase.action`

export {
  getListURL,
  exportListURL,
  getNationURL,
  saveURL,
  getDetailURL
}
